


































































































































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsWizardRetirementFundModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-retirement-fund-model';

@Component({
  name: 'FlagshipGoalsWizardRetirementFund',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlagshipGoalsWizardRetirementFund extends Vue {
  flagship_retirement_fund_model = Vue.observable(new FlagshipGoalsWizardRetirementFundModel(this));

  created() {
    this.flagship_retirement_fund_model.initialize();
  }
}
